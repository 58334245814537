function Card({icon, customStyle, head, body}) {
  return (
    <div className={"min-[1000px]:w-[250px] w-[100%] border-solid border-[#fafcff] border-[1px] rounded-[5px] p-[20px] bg-[#ffffff] shadow-[#e7eaf3b3] shadow-2xl " + customStyle}>
      <img src={icon} />
      <div className="font-inter text-[20px] font-bold my-[10px] leading-[30px]">{head}</div>
      <div className="font-inter text-[#677788] leading-[30px]">{body}</div>
    </div>
  );
}

export default Card;