function Header() {

  function onMenuItemClicked(tag){
    if (tag == '#home') {
      window.scrollTo({
        top: document.querySelector(tag).offsetTop - 200,
        left: 0,
        behavior: 'smooth'
      })
    } else if (tag == '#aboutus') {
      window.scrollTo({
        top: document.querySelector(tag).offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    } else if (tag == '#services') {
      window.scrollTo({
        top: document.querySelector(tag).offsetTop - 150,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div className="w-[100%] bg-[#ffffff] sticky top-[0px] z-[1] flex items-center mx-[auto]">
      <div className="min-[1900px]:w-[45%] xl:w-[80%] w-[90%] h-[70px] flex ml-[auto] mr-[auto] justify-end">
        <div className="min-[1000px]:w-[65%] h-[100%] flex items-center text-[#000000] justify-end">
          <div className="sm:flex hidden">
            <div className="mr-10 text-[#1E2022] font-inter cursor-pointer" onClick={() => onMenuItemClicked('#home')}>Home</div>
            <div className="mr-10 text-[#1E2022] font-inter min-w-[70px] cursor-pointer" onClick={() => onMenuItemClicked('#aboutus')}>About Us</div>
            <div className="mr-10 text-[#1E2022] font-inter cursor-pointer" onClick={() => onMenuItemClicked('#services')}>Services</div>
          </div>
          <a href="tel:07507932938">
            <div className="bg-[#3779E0] px-[22px] py-[10px] rounded-[5px] text-[#ffffff] flex items-center cursor-pointer my-[5px] min-w-fit text-[15px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
              <span className="ml-[10px]">Call Now</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;





// $('ul.nav').find('a').click(function(){
//     var $href = $(this).attr('href');
//     var $anchor = $('#'+$href).offset();
//     window.scrollTo($anchor.left,$anchor.top);
//     return false;
// });