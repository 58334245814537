import fixingSink from './../fixing_sink.jpg'
import smartPlumbing from './../sm3.jpg'
import fixingBathtub from './../fixing_bathtub.jpg'
import circle from './../circle.png'
import gasServices from './../gas.jpg'
import plumbersBath from './../plumbers-bath.jpg'
import repair from './../repair.jpg'
import bathroom from './../bathroom.jpg'
import Card from './mainSection/card.js'
import Slider from './mainSection/slider.js'
import LogoSlider from './mainSection/logoSlider.js'
import card1 from './../card1.png'
import card2 from './../card2.png'
import card3 from './../card3.png'

function MainSection() {
  return (
    <div className="w-[100%] pt-[30px] bg-[#fafcff]">

      <div id="home" className="flex min-[1900px]:w-[45%] xl:w-[80%] w-[90%] mx-[auto] justify-between items-center">

        <div className="w-[100%] md:w-[50%] text-left leading-none">
          <div className="mb-[10px] flex items-center">
            <span className="text-[40px] font-bold text-[#377ae0]">Smart</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-fire" viewBox="0 0 16 16">
              <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16m0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15"/>
            </svg>
          </div>
          <div>
            <span className="text-[60px] font-bold">Plumbing & Heating</span>
          </div>
          <div className="my-[50px] font-sans text-[#677788] text-[20px] leading-[30px]">
            Your go-to professionals for plumbing and heating solutions in Bristol.
          </div>
          <div className="mt-[20px]">
            <a className="w-fit h-fit" href="https://crm.zohopublic.eu/crm/WebFormServeServlet?rid=431deb07b753b1541d384b73d07f4d41e102418bf33a7faa575800ec1468db99484ce7cb2c00aa2e7fab7fb4752bb020gidbcc29b827d4c7b11988823f67ab590794a6009d754fa2ada8e6c07868e0400a5" target="_blank">
              <button className="bg-[#4a60d2] px-[30px] py-[20px] rounded-[5px] text-[#ffffff]">
                Request Callout
              </button>
            </a>
          </div>
        </div>

        <div className="w-[50%] md:flex justify-end hidden">
          <img className="w-[80%] h-max rounded-[5px]" src={fixingSink}/>
        </div>

      </div>

      <div className="min-[1900px]:w-[45%] xl:w-[80%] w-[90%] mx-[auto] text-left flex max-[1000px]:flex-col-reverse justify-between">

        <div className="flex max-[1000px]:flex-col">

          <div>
            <Card icon={card1} head="Plumbing Heating Solutions" body="Our experienced engineers provide a wide range of plumbing and heating solutions in and around the Bristol area."/>
            <Card icon={card3} customStyle="mt-[30px]" head="Plumbing Maintenance" body="Whether you have leaky taps to faulty boilers, we provide comprehensive maintenance services."/>
          </div>

          <div className="min-[1000px]:my-[auto] min-[1000px]:ml-[30px] max-[1000px]:mt-[30px]">
            <Card icon={card2} head="Emergency Plumbing" body="When you need us most, use our emergency callout service and we aim to assist you instantly."/>
          </div>

        </div>

        <div className="min-[1000px]:my-[auto] my-[30px] min-[1000px]:ml-[30px]">
          <div className="text-[33px] font-bold leading-[45px] my-[20px]">20 years of expereince in Plumbing and Heating</div>
          <div className="text-[#677788]">We are trusted and experienced gas safe engineers and provide aftercare for complete peace of mind.</div>
        </div>

      </div>

      <div id="aboutus" className="min-[1900px]:w-[45%] xl:w-[80%] w-[90%] mx-[auto] my-[50px] sm:py-[100px]">
        <div className="font-bold text-[32px] mb-[30px]">
          Passionate about plumbing & heating solutions
        </div>
        <div className="text-[#677788] text-[20px] sm:leading-[45px]">
          At Smart Plumbing & Heating Bristol, our expertise spans a comprehensive range of services. We specialise in the installation and maintenance of central heating systems, boilers, and plumbing systems, ensuring your home in Bristol stays comfortable and efficient at all times. Our team is proud to be an approved installer for leading brands such as Worcester Bosch, Ideal, Baxi, Weisman, Atag Heztechnik, and Valiant Hezsystems. Plus, we're always ready to respond with our 24/7 emergency plumbing support.
        </div>
      </div>

      <div className="bg-[#5851460d] w-[100%] sm:py-[100px] py-[50px]">
        <div className="min-[1900px]:w-[45%] xl:w-[80%] w-[90%] mx-[auto] flex">
          <div className="w-[50%] min-[900px]:flex items-center hidden">
            <img src={smartPlumbing} className="rounded-[5px] mt-[-20px]"/>
          </div>
          <div className="md:ml-[100px]">
            <div className="font-bold text-[32px] text-left mb-[50px]">Our exceptional service</div>
            <div className="flex">
              <div>
                <img src={circle}/>
                <div>|</div>
                <div>|</div>
                <div>|</div>
                <div>|</div>
              </div>
              <div className="text-left ml-[30px]">
                <div className="mt-[-8px] font-bold text-[20px]">Rapid response to emergencies</div>
                <div className="sm:mt-[25px] text-[16px] text-[#697B92]">We are there when you need us most</div>
              </div>
            </div>
            <div className="flex">
              <div>
                <img src={circle}/>
                <div>|</div>
                <div>|</div>
                <div>|</div>
                <div>|</div>
              </div>
              <div className="text-left ml-[30px]">
                <div className="mt-[-8px] font-bold text-[20px]">A team you can rely on</div>
                <div className="sm:mt-[25px] text-[16px] text-[#697B92]">Our flexible and experienced team on hand</div>
              </div>
            </div>
            <div className="flex">
              <div>
                <img src={circle} className="w-[25px]"/>
              </div>
              <div className="text-left ml-[30px]">
                <div className="mt-[-8px] font-bold text-[20px]">Support around the clock</div>
                <div className="sm:mt-[25px] text-[16px] text-[#697B92]">Available 24/7/365 days a year, because we know plumbing emergencies don't follow a schedule</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services" className="min-[1900px]:w-[45%] xl:w-[80%] w-[90%] mx-[auto] flex sm:my-[100px] my-[20px] justify-between">
        <div className="text-left">
          <div className="font-bold text-[32px]">Our Top-Rated Services</div>
          <div className="flex mt-[50px]">
            <div className="mt-[8px]">
              <img src={gasServices} width="40px"/>
            </div>
            <div className="ml-[20px]">
              <span className="font-bold text-[20px]">Gas appliance installation</span>
              <br/>
              <span className="text-[16px] text-[#697B92]">Expertly handled by our certified and licensed specialists.</span>
            </div>
          </div>
          <div className="flex mt-[30px]">
            <div className="mt-[8px]">
              <img src={plumbersBath} width="40px"/>
            </div>
            <div className="ml-[20px]">
              <span className="font-bold text-[20px]">Plumbers in Bristol & Bath</span>
              <br/>
              <span className="text-[16px] text-[#697B92]">We cover Bristol, Bath and surrounding areas.</span>
            </div>
          </div>
          <div className="flex mt-[30px]">
            <div className="mt-[8px]">
              <img src={repair} width="40px"/>
            </div>
            <div className="ml-[20px]">
              <span className="font-bold text-[20px]">Comprehensive repair, servicing & maintenance</span>
              <br/>
              <span className="text-[16px] text-[#697B92]">All our services come with aftercare and helpful advice</span>
            </div>
          </div>
          <div className="flex mt-[30px]">
            <div className="mt-[8px]">
              <img src={bathroom} width="40px"/>
            </div>
            <div className="ml-[20px]">
              <span className="font-bold text-[20px]">Expert installation</span>
              <br/>
              <span className="text-[16px] text-[#697B92]">Building and renovating ? we would love to work with you on your project</span>
            </div>
          </div>
        </div>
        <div className="w-[50%] hidden min-[900px]:block md:ml-[70px] my-[auto]">
          <div className="w-[84%] ml-[auto] my-[auto]">
            <img src={fixingBathtub} className="w-[100%] rounded-[5px]"/>
          </div>
        </div>
      </div>
      <Slider />
      <LogoSlider />
      <hr/>
      <div className="my-[30px] min-[1000px]:flex mx-[auto] min-[1900px]:w-[45%] xl:w-[80%] w-[90%] justify-between">
        <div className="">
          <div className="flex max-[1000px]:justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-fire" viewBox="0 0 16 16">
              <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16m0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15"/>
            </svg>
            <span className="text-[20px] mt-[-4px]">Smart</span>
          </div>
          <span className="text-[20px]">Plumbing & Heating</span>
        </div>
        <div className="font-inter text-[#1E2022] flex text-left max-[1000px]:justify-center max-[1000px]:my-[50px]">
          <div className="mr-[30px]">
            <div className="cursor-pointer">Our Services</div>
            <div className="mt-[20px] cursor-pointer">Gas Engineers</div>
            <div className="mt-[20px] cursor-pointer">Plumbing Services</div>
            <div className="mt-[20px] cursor-pointer">Boiler installation</div>
          </div>
          <div className="mr-[30px]">
            <div className="cursor-pointer">Emergency Plumbers</div>
            <div className="mt-[20px] cursor-pointer">Plumbers 24/7 hours</div>
            <div className="mt-[20px] cursor-pointer">Plumbers</div>
          </div>
        </div>
        <div className="min-[1000px]:text-left">
          <div className="flex h-min items-center max-[1000px]:justify-center">
            <div className="text-[20px] font-bold">Find Us</div>
            <a href="tel:07507932938">
              <div className="bg-[#3779E0] p-[20px] ml-[20px] rounded-[5px] text-[#ffffff] flex items-center cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                </svg>
                <span className="ml-[10px]">Call Now</span>
              </div>
            </a>
          </div>
          <div className="mt-[20px] cursor-pointer">info@smartplumbingheatingbristol.co.uk</div>
          <div className="mt-[20px]">31 Lansdown Road, Bs15 1xa</div>
        </div>
      </div>
    </div>
  );
}

export default MainSection;