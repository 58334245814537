import React, { useState, useEffect } from 'react';

import brand1 from './../../brand_logos/brand1.png'
import brand2 from './../../brand_logos/brand2.png'
import brand3 from './../../brand_logos/brand3.png'
import brand4 from './../../brand_logos/brand4.jpg'
import brand5 from './../../brand_logos/brand5.webp'
import brand6 from './../../brand_logos/brand6.png'
import brand7 from './../../brand_logos/brand7.jpg'
import brand8 from './../../brand_logos/brand8.png'
import brand9 from './../../brand_logos/brand9.jpg'

const LogoSlider = () => {
  const leftNavigation = '<'
  const rightNavigation = '>'
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    [brand1, brand2, brand3, brand8],
    [brand4, brand5, brand6, brand2],
    [brand7, brand8, brand9, brand5]
  ]; // Your slide content
  const intervalTime =10000; // 10 seconds in milliseconds

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const changeSlide = () => {
    const nextSlide = (currentSlide + 1) % slides.length;
    setCurrentSlide(nextSlide);
  };

  useEffect(() => {
    const interval = setInterval(changeSlide, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);
  return (
    <div className="">
      <div className="w-[100%] py-[30px]">
        <div className="min-[1900px]:w-[45%] xl:w-[80%] w-[90%] mx-[auto] overflow-hidden">
          <div
            className="my-[30px] items-center"
            style={{
              display: 'flex',
              width: `${slides.length * 100}%`,
              transform: `translateX(-${currentSlide * (100 / slides.length)}%)`,
              transition: 'transform 1s ease-in-out',
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                className="slide flex items-center"
                style={{
                  width: `${100 / slides.length}%`,
                  textAlign: 'center',
                }}
              >
                <div className="flex w-[100%] justify-evenly">
                  {slide.map((sub_slide, index2) => (
                    <div key={index2} className="w-[150px] h-[100px] flex items-center mx-[20px]">
                      <img src={sub_slide} width="100%"/>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*<div className="flex mx-[auto] justify-center">
          {slides.map((_, index) => (
            <svg onClick={() => handleDotClick(index)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={(index === currentSlide ? '#ff006e' : '#000000')} className={"bi bi-circle-fill " + "mx-[5px] cursor-pointer"} viewBox="0 0 16 16">
              <circle cx="8" cy="8" r="8"/>
            </svg>
          ))}
        </div>*/}
      </div>
    </div>
  );
};

export default LogoSlider;
