import React, { useState, useEffect } from 'react';

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {review: '"Very good work, I had an urgent job and they came only a few hours after calling. They sealed off the leaking pipe and ensured my gas supply was safely reconnected. Price was cheaper than original quote as the job was not as complicated as it first appeared. I am very happy with their work."',
    name: 'Mosh'},
    {review: '"I was really impressed with the work carried out by Smart Plumbing and Heating. They were extremely professional, ensured the work was carried out to the hugest of standards whilst making sure there was minimal disruptions. Would recommend Smart Plumbing and Heating to anyone and I will always use them in future for any of my work."',
    name: 'John'},
    {review: '"Very good Smart Plumbing never fail to disappoint me, they are excellent in what they do and always carry out their jobs with great quality and provide excellent service each time. They are fast and easy to contact and will come to your attendance anytime there is an emergency, whether its a small job or big. A very helpful company who provide a reliable service each time. I will be contacting Smart Plumbing for all my future jobs."',
    name: 'MethaB'},
    {review: '"Spectacular job carried out Very pleased with my new boiler. Also done at a fair price"',
    name: 'Rahman'},
    {review: '"Great professional kept my dad informed ordered the required part for boiler needed. Efficient"',
    name: 'Jackson'},
    {review: '"Quick response and excellent work. Aminur did not charge for a few extra work he did which was not in his quote."',
    name: 'Akib'},
    {review: '"Excellent quality of work and completely quickly. The price was fair and the plumber was friendly and professional. Would use them again and recommend to others."',
    name: 'Shane'},
    {review: '"Amin and his colleague were brilliant. I called them out as an emergency and they contained the leak very quickly without making a mess. He was very reassuring and definitely has great knowledge and explained the cause, the best option and cost of fixing very well. They were very pleasant and their time keeping was impeccable. Will now be using them as preferred plumbers. I would highly recommend them"',
    name: 'Allien John'}
  ]; // Your slide content
  const intervalTime =10000; // 10 seconds in milliseconds

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const changeSlide = () => {
    const nextSlide = (currentSlide + 1) % slides.length;
    setCurrentSlide(nextSlide);
  };

  useEffect(() => {
    const interval = setInterval(changeSlide, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  return (
    <div className="slider">
      <div className="w-[100%] py-[30px] bg-[#fdf6f0]">
        <div className="min-[1900px]:w-[45%] xl:w-[80%] w-[90%] mx-[auto] overflow-hidden sm:my-[50px] my-[10px]">
          <div className="font-extrabold text-[32px]">Client Reviews</div>
          <div
            className="my-[30px]"
            style={{
              display: 'flex',
              width: `${slides.length * 100}%`,
              transform: `translateX(-${currentSlide * (100 / slides.length)}%)`,
              transition: 'transform 1s ease-in-out',
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                className="slide"
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  width: `${100 / slides.length}%`,
                  textAlign: 'center',
                }}
              >
                <div className="text-[16px] text-[#697B92] mb-[30px]">{slide.review}</div>
                <span className="font-bold text-[#697B92]">{slide.name}</span>
              </div>
            ))}
          </div>
          {/*<div className="flex mx-[auto] justify-center">
            {slides.map((_, index) => (
              <svg onClick={() => handleDotClick(index)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={(index === currentSlide ? '#ff006e' : '#000000')} className={"bi bi-circle-fill " + "mx-[5px] cursor-pointer"} viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8"/>
              </svg>
            ))}
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Slider;
